import React, { useEffect, useState } from "react";
import {
	Card,
	CardContent,
	Grid,
	Box,
	FormControl,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from '@material-ui/core/styles';
import { getCurrentUser } from "../../../../core/helpers";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import "./card-user.scss";

const objectSignature = {
	link: "Liga por correo",
	email: "Correo con Contraseña",
	rfc: "R.F.C.",
	token: "Token",
};

const objectStroke = {
	any: "Cualquiera",
	desc: "Descriptiva",
	stroke: "Dibujo",
	name: "Nombre",
	hash: "Digestión",
	qr: "QR",
};

const useStyles = makeStyles((theme) => ({
	card: {

	}
}))

export const CardUserFn = ({
	name,
	email,
	role,
	color,
	index,
	order,
	labelType,
	onlyShow,
	useRFC,
	onChange,
	id,
	type,
	rfc,
	stroke,
	disableMethod,
	disableStroke
}) => {
	const classes = useStyles();
	const [selectedOption, setSelectedOption] = useState("");
	const [selectedStroke, setSelectedStroke] = useState("");
	const [options, setOptions] = useState([]);
	const [strokeOptions, setStrokeOptions] = useState([]);
	const [disableDrag, setDisableDrag] = useState(false);
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id, disabled: disableDrag });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};
	const formatText = (text) => {
		if (labelType === "revision") {
			return text;
		}
		if (!text) {
			return "";
		}
		if (text.length > 30) {
			return `${text.substring(0, 29)}...`;
		}
		return text;
	};


	const handleChangeStroke = (e) => {
		setSelectedStroke(e.target.value);
	};

	const handleChange = (e) => {
		setSelectedOption(e.target.value);
	};

	const availableOptions = () => {
		const { availableSignatures, defaultStroke } = getCurrentUser();

		const optionsMap = availableSignatures.map((el) => ({
			title: objectSignature[el],
			value: el,
		}));

		const strokeMap = Object.keys(objectStroke).map((el) => ({
			title: objectStroke[el],
			value: el,
		}));

		const optionsSet = useRFC
			? optionsMap
			: optionsMap.filter((op) => op.value !== "rfc");

		setOptions(optionsSet);
		setStrokeOptions(strokeMap);
		setSelectedStroke(stroke ?? defaultStroke);
		setSelectedOption(type ?? optionsSet[0].value);
		// type === undefined && onChange({
		//   userId: id,
		//   name,
		//   email,
		//   color,
		//   type: optionsSet[0].value,
		//   rfc,
		//   hasRFC: useRFC,
		//   stroke: defaultStroke
		// });
	};

	useEffect(() => {
		availableOptions();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!selectedOption || !selectedStroke) return;
		onChange({
			userId: id,
			name,
			email,
			color,
			type: selectedOption,
			rfc,
			hasRFC: useRFC,
			stroke: selectedStroke,
		});
		// eslint-disable-next-line
	}, [selectedOption, selectedStroke]);

	return (
		<Grid item {...attributes} {...listeners} ref={setNodeRef} style={style}>
			<Card className={classes.card}>
				<CardContent>
					<Grid container spacing={1} alignItems="center">
						<Grid item xs>
							<Box display={"flex"} >
								{order ? <div style={{ padding: '12px 12px 0px 0', color: "#5C7BF0", fontWeight: "bold" }}>{index + 1}</div> : ''}
								<div
									className={`${labelType === "revision" ? "col-md-11 col-xs-11" : ""
										} pl-3`}>
									<p className="endorsement-label">
										<span title={role}>
											{role}
										</span>
									</p>
									<span title={name}>{formatText(name)}</span>
									<br />

									<em className="primary-text" title={email}>
										{formatText(email)}
									</em>
								</div>
							</Box>
						</Grid>

						<Grid item container spacing={1} justifyContent="flex-end" xs={6}>
							<Grid item>
								{labelType === "firm" && !onlyShow && (
									<FormControl>
										<span className="label-metodo">Método</span>
										<Select
											className="select"
											onOpen={() => setDisableDrag(true)}
											onClose={() => setDisableDrag(false)}
											value={selectedOption}
											onChange={(e) => handleChange(e)}
											title={objectSignature[selectedOption]}
											disabled={disableMethod}>
											{options.map((el) => (
												<MenuItem key={el.value} value={el.value}>
													{el.title}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								)}

								{labelType === "firm" && onlyShow && (
									<div className="primary-text">
										{objectSignature[selectedOption]}
									</div>
								)}
							</Grid>
							<Grid item>
								{(labelType === "firm" && !onlyShow) && (
									<FormControl>
										<span className="label-metodo">Trazo</span>
										<Select
											className="select"
											onOpen={() => setDisableDrag(true)}
											onClose={() => setDisableDrag(false)}
											value={selectedStroke}
											onChange={handleChangeStroke}
											title={objectStroke[selectedStroke]}
											disabled={disableStroke}>
											{strokeOptions.map((el) => (
												<MenuItem key={el.value} value={el.value}>
													{el.title}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								)}

								{labelType === "firm" && onlyShow && (
									<div className="primary-text">
										{objectStroke[selectedStroke]}
									</div>
								)}
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};
