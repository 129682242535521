import { Badge, Button, Dialog, IconButton, Menu } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { uuid, Alert, DocumentPdfViewer } from 'axeleratum-sgc-frontend-library';
import { Configurations } from './configurations';
import { NotificationItem } from './notification-item';
import { NotificationHttp } from '../../../core/http/notification.http';
import { DocumentsHttp } from '../../../core/http/documents.http';
import { CalendarHttp } from '../../../core/http/calendar.http';
import saveAs from 'file-saver';
import './notification.scss';
import { useEffect, useState } from 'react';
import { DialogSignatureStatus } from '../../dialogs/activities/DialogSignatureStatus';
import DocumentReview from '../../vault/document-review';
import { tokenExpired } from '../../../core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../core/actions';

let intervalRef = null;

export const NotificationFn = () => {

  const notificationHttp = new NotificationHttp();
  const documentsHttp = new DocumentsHttp();
  const calendarHttp = new CalendarHttp();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [openPdf, setOpenPdf] = useState(false);
  const [filePdf, setFilePdf] = useState('');
  const [titlePdf, setTitlePdf] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [typeMessage, setTypeMessage] = useState('');
  const [message, setMessage] = useState('');
  const [loadId, setLoadId] = useState(null);
  const [openActivity, setOpenActivity] = useState(false);
  const [activity, setActivity] = useState(null);

  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);
  const dispatch = useDispatch();


  const findAll = () => {


    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }



    setLoad(true);
    notificationHttp.findAll().then(({ data }) => {
      setNotifications(data);
      setLoad(false);
    }).catch(() => {
      setLoad(false);
      if (intervalRef) {
        clearInterval(intervalRef);
      }
    });
  }

  const openDocument = (id, documentId) => {
    setLoadId(id)



    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }

    documentsHttp.downloadDocumentById(documentId,
      (resp, extension) => {
        if (extension.toLowerCase() === '.pdf') {
          const url = URL.createObjectURL(new Blob([resp.data], {
            type: "application/pdf"
          }));
          setFilePdf(url)
          setTitlePdf(document.name)
          setOpenPdf(true);
        } else {
          const blob = new Blob([resp.data], { type: 'application/octet-stream' });
          saveAs(blob, `${document.name}${extension}`);
        }

        setLoadId(null)
      }, (error) => {
        setTypeMessage('error')
        setMessage('Ocurrió un error al abrir el documento. Intente más tarde.')
        setLoadId(null)
        setOpenAlert(false)
      }
    );
  }

  const findActivityById = (id, activityId, item) => {



    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    setLoadId(id)


    calendarHttp.findActivityById(activityId,
      (data) => {
        if (data.type === "revision") {
          setActivity({ id: item.documentId, name: item.documentName, type: "revision" })
        } else {
          setActivity(data);
        }
        setOpenActivity(true);
        setLoadId(null)
      }, () => {
        setTypeMessage('error')
        setMessage('Ocurrió un error al abrir la actividad. Intente más tarde.')
        setLoadId(null)
        setOpenAlert(false)
      });
  }

  const updateSignatureStatus = () => {
    const onError = (error) => {
      setTypeMessage('error')
      setMessage('Ocurrió un error al abrir la actividad. Intente más tarde.')
      setOpenAlert(false)
    }

    const onSuccess = (data) => {
      if (data.type === "revision") {
        setActivity({ id: activity.documentId, name: activity.documentName, type: "revision" })
      } else {
        setActivity(data);
      }
      setOpenActivity(true);
    }



    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }

    calendarHttp.findActivityById(activity.id.toString(), onSuccess, onError)
  }

  const handleCancel = () => {
    setActivity(null);
    setOpenActivity(false);
  }

  useEffect(() => {
    findAll();
    intervalRef = setInterval(() => {
      findAll();
    }, 180000);

    return () => {
      if (intervalRef) {
        clearInterval(intervalRef);
      }
    }
  }, [])

  return (
    <>
      <IconButton
        className="mr-2 button-border"
        variant="outlined"
        onClick={(evt) => setAnchorEl(evt.currentTarget)}
      >
        <Badge className="badge-red" badgeContent={notifications.length} color="primary">
          <NotificationsIcon color="primary" />
        </Badge>
      </IconButton>

      <Dialog open={open}>
        <Configurations onClose={() => setOpen(false)} />
      </Dialog>

      <DocumentPdfViewer
        url={filePdf}
        title={titlePdf}
        open={openPdf}
        onClose={() => setOpenPdf(false)}
      />

      <Alert
        open={openAlert}
        title={message}
        onConfirm={() => setOpenAlert(false)}
        type={typeMessage}
      />

      <Menu
        className="menu-size"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <div className="container notification col-md-12">
          <div className="row">
            <div className="col-md-8">
              <h4>Notificaciones</h4>
            </div>
            <div className="col-md-12 notification-card">
              <hr />
            </div>
          </div>

          {
            load ?
              <div>Cargando notificaciones...</div>
              :
              notifications.map(item => (
                <NotificationItem
                  key={uuid()}
                  item={item}
                  load={item.id === loadId}
                  onView={() => findAll()}
                  onOpenFile={(id, documentId) => openDocument(id, documentId)}
                  onOpenActivity={(id, activityId) => findActivityById(id, activityId, item)}
                />
              ))
          }

          {
            notifications.length === 0 &&
            <div className="text-center mb-3">No hay notificaciones por mostrar</div>
          }
        </div>
      </Menu>

      {
        (["firm", "endorse"].includes(activity?.type) && openActivity) && (
          <DialogSignatureStatus
            open={openActivity}
            setOpen={setOpenActivity}
            item={activity}
            onExitDetail={handleCancel}
            onUpdate={updateSignatureStatus}
          />
        )
      }

      {
        (activity?.type === "revision" && openActivity) && (
          <DocumentReview
            immediatelyRevision={false}
            openDialog={openActivity}
            onClose={handleCancel}
            closeModal={handleCancel}
            document={activity}
          />
        )
      }
    </>
  )
}
