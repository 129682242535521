import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@material-ui/core';
import React, { Component } from 'react';

export class TextFieldNative extends Component {

  render() {
    const { label, value, message, type, onChange, onBlur, inputProps } = this.props;

    return (
      <FormControl
        fullWidth={true}
        variant="outlined"
        margin="dense"
        error={!!message}
      >
        <InputLabel
          style={{ background: 'white' }}
        >
          {label}
        </InputLabel>

        <OutlinedInput
          {...inputProps}
          value={value}
          type={type ? type : 'text'}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur ? onBlur : () => { }}
        />

        <FormHelperText>{message}</FormHelperText>
      </FormControl>
    );
  }


}