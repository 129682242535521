import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { activityActions } from '../../../../core/actions/activity.actions';
import { FormTemporalUsers } from '../../../team/users/FormTemporalUsers';
import { AlertConfirm } from '../../../from-library/alerts/AlertConfirm';

export const AddEndorsee = () => {
	const dispatch = useDispatch();

	const availableUsers = useSelector(state => state.activityReducer.usersToSign);
	const temporalToSign = useSelector(state => state.activityReducer.temporalUsersToSign);
	const selectedEndorsee = useSelector(state => state.activityReducer.endorsee);
	const selectedEndorser = useSelector(state => state.activityReducer.endorser);

	const [checked, setChecked] = useState(selectedEndorsee);
	const [openList, setOpenList] = useState(false);
	const [openExternalForm, setOpenExternalForm] = useState(false);
	const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
	const [temporalSelected, setTemporalSelected] = useState(null);
	const [userReference, setUserReference] = useState(null);


	const handleChange = (user) => {
		const currentIndex = checked.map(e => e.userId).indexOf(user.userId);
		const newChecked = [];


		if (!!user.temporal || temporalToSign.some(el => !el.endorser)) {
			setTemporalSelected(temporalToSign[0].userId);
			setOpenConfirmAlert(true);
			setUserReference(user)
			return
		}

		if (currentIndex === -1) {
			newChecked.push(user);
		}
		setChecked(newChecked);
	}

	const handleAddEndorsee = () => {
		dispatch(activityActions.saveEndorsee([checked[0]]));
		dispatch(activityActions.saveTemporalEndorsee(checked[0]));
		setOpenList(false);
	};

	const handleCancel = () => {
		setOpenList(false);
		setChecked(selectedEndorsee);
	}

	const handleSubmitExternalUser = (user) => {
		const endorsee = {...user, endorser: false}
		dispatch(activityActions.saveTemporalUserToSign([endorsee]));
		setChecked([endorsee]);
		setOpenExternalForm(false);
	}

	return (
		<>
			<div style={{ padding: "5px 15px 5px 5px" }}>
				<Button
					color="primary"
					variant="contained"
					onClick={() => setOpenList(true)}>
					endosatario (opcional)
				</Button>
			</div>

			{openList && (
				<Dialog open={openList}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-around",
						}}>
						<DialogTitle>Seleccionar Endosatario</DialogTitle>

						<div style={{ padding: "20px" }}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									setOpenExternalForm(true);
								}}>
								Añadir Externo
							</Button>
						</div>
					</div>
					<DialogContent style={{ paddingTop: "0px", marginTop: "-16px" }}>
						<List
							style={{ paddingTop: "0px" }}
						>
							{[...availableUsers, ...temporalToSign].filter(el => el.id !== selectedEndorser[0]?.id).map((item, index) => (
								<ListItem
									key={`${item.userId}-${index}`}
									onClick={() => handleChange(item)}
									style={{ paddingTop: "0px", paddingBottom: "0px" }}
								>
									<Checkbox
										id={`check${item.userId}`}
										checked={checked.some(
											(some) => some.userId === item.userId
										)}
										/>
									<ListItemText

										primary={
											!!item.temporal
												? `${item?.name} (externo 1 vez)`
												: item?.name
										}
									/>
								</ListItem>
							))}
						</List>

					</DialogContent>
					<DialogActions
					// style={{ justifyContent: "flex-end" }}
					>
						<Button
							className="primary-text"
							color="primary"
							variant="outlined"
							onClick={handleCancel}>
							Cancelar
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={handleAddEndorsee}
						>
							Aceptar
						</Button>
					</DialogActions>
				</Dialog>
			)}

			{openExternalForm && (
				<FormTemporalUsers
					open={openExternalForm}
					onCancel={() => setOpenExternalForm(false)}
					submitActions={handleSubmitExternalUser}
				/>
			)}

			{openConfirmAlert && (
				<AlertConfirm
					open={openConfirmAlert}
					onCancel={() => setOpenConfirmAlert(false)}
					onConfirm={() => {
						dispatch(activityActions.deleteTemporalSigner(temporalSelected));
						setChecked([userReference])
						setTemporalSelected(null);
						setOpenConfirmAlert(false);
					}}
					textContent="El usuario externo se eliminará de la lista. ¿Deseas continuar?"
				/>
			)}
		</>
	);

}
